import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderFour";
import Footer from "../../component/footer/Footer";
import TravelandHospitalityImageTextContainer from "./Travel&Hospitality_ImageandText";
import SoftwareDevelopment from './SoftwareDevelopment.jsx';
import WhyUnanimous from "./WhyUnanimous";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import { FaPlaneDeparture, FaShippingFast, FaHotel } from 'react-icons/fa';
import bgImageTravel from '../../../public/assets/images/bg/bg-image-38.jpg'


const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Healthcare extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Healthcare' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area ptb--40 pb--190 bg_image bg_image--36"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Travel and Hospitality Management Software</h2>
                                    <p>Create a richer experience to make your guests' happy and satisfied</p>
                                    <p>Delivering accustomed digital transformations and technology solutions that bring richer travel experiences and delightful customers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Healthcare management Area  */}
                <div className="rn-about-area ptb--40 bg_color--1" style={{paddingTop: "80px"}}>
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row align-items-center" style={{margin: "20px"}}>
                                <div className="col-lg-12 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">Smooth Hotel Management Software</h2>
                                            <p>Healthcare organizations need to stay healthy with the technology dose to operate smartly, accelerate strategic goals and revamp the quality of Service</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Healthcare management Area  */}

                {/* Start Image text Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 bg_image bg_image--37" style={{backgroundAttachment: "fixed", backgroundSize:"cover", backgroundPosition:"left bottom"}}>
                                <div className="col-lg-6 col-m-6 col-sm-12 pt--120"  >
                                        <div style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                                        <h2>Revive Health IT</h2>
                                    <h2>Add Techho-Care</h2>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Image text Area  */}
                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaPlaneDeparture />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Airline</h4>
                                        <p>Take off hrights with business enabler solutions and engage customers</p>
                                        {/* <p><a href="tel:+856 325 652 984">+856 325 652 984</a></p> */}
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaShippingFast />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Logistics</h4>
                                        <p>Domain rich solutions to accelerate effeciency, revenue and real-time insights.</p>
                                        {/* <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> */}
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaHotel />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Hospitality</h4>
                                        {/* <p>307,Sundarpark, Plot no. 95, Sec 8 <br /> Gandhidham, Gujrat-370201</p> */}
                                        <p>Enhance brand reputation with analytics, mobility and resolve issues swiftly.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row align-items-center" style={{margin: "20px"}}>
                                <div className="col-lg-12 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">Healthcare Management Software</h2>
                                            <p>Enabling a healthy ecosystem with Healthcare software development - that empowers organizations to develop loyal users, make a difference with superior health outcomes and reduce operational costs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                {/* Start Contact Top Area  */}
                <div className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 ptb--40" >
                            <div className="container-fluid">
                                <TravelandHospitalityImageTextContainer />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                    <div className="rn-about-wrapper" style={{backgroundColor: "#F5F5F5"}}>
                        <div className="container-padding-right">
                            <div className="row" style={{paddingTop: "40px"}} >
                            <div className="container">
                            <h1>Healthcare Software Development</h1>
                                <SoftwareDevelopment />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>
                    {/* Start Image text Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 new-tech " style={{backgroundImage: `url(${bgImageTravel})`, backgroundAttachment: "fixed", backgroundSize:"cover", backgroundPosition:"left bottom"}}>

                            <div className="col-lg-6 col-m-6 col-sm-12 pt--120" >
                                        
                                </div>
                                <div className="col-lg-6 col-m-6 col-sm-12 pt--120 " style={{backgroundColor:"rgba(182,217,213,1)", color:"#ffffff"}} >
                                        <div style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                                        <h2 class="travelFont">Why choose our Travel Software Solutions?</h2>
                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>

                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>

                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>
                                        </div>
                                        
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Image text Area  */}            

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Healthcare