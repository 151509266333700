import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Footer from "../component/footer/Footer";
import CarouselOfImages from "./CarouselOfImages";
import ContactTwo from "../elements/contact/ContactTwo";
import { NavLink } from "react-router-dom";



class About extends Component{
    render(){
        let title = 'About',
        description = 'We help business to build and scale their own dedicated teams at a competitive price in Canada';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                {/* <Breadcrumb title={'About'}   /> */}
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right" style={{backgroundColor: "#282c4e"}}>
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">{title}</h2>
                                            <p className="description">{description}</p>
                                                <a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">
                                                    <span>Get a Quote Now →</span>
                                                </a>
                                                <p></p>
                                            <p className="description">100% Confidential and Secure</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Mission</h3>
                                                    <p>We aim at transforming the digital experience of our customers into cost effective, functional, user-centric and innovative technical solutions. Our company recognizes and adapts quickly to the changing digital landscape thereby empowering clients to uplift their presence in the market.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-m-12" >
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Mission Area  */}
                <div className="container rn-counterup-area pb--120">
                    <div className="row row--0 align-items-center mission-vission">
                        <div className="col-lg-6 col-m-12">
                            <div className="about-inner inner">
                                <div className="section-title text-center">
                                    <h2 className="title theme-gradient">Mission</h2>
                                    <p className="description">We aim at transforming the digital experience of our customer into cost effective, functional, user-centric and innovative technical solution, Our company recognizes and adapts quickly to the changing digital landscape thereby empowering clients to uplift their presence in the market.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-m-12" >
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/finding-us-01.jpg" alt="About Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Mission Area  */}

                {/* Start Vision Area  */}
                <div className="container rn-counterup-area pb--120">
                    <div className="row row--0 align-items-center mission-vission">
                        <div className="col-lg-6 col-m-12" >
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/about/finding-us-02.jpg" alt="About Images"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-m-12">
                            <div className="about-inner inner">
                                <div className="section-title text-center">
                                    <h2 className="title theme-gradient">Vision</h2>
                                    <p className="description">We envision becoming a global leader in outsourcing and offshoring technological solutions. Our eye into the details,quality assurance and meticulous selection pf talent will help business to evolve into profitable assets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Vision Area  */}

                {/* Start of Core Values section Area  */}
                
                <div className="rn-about-area ptb--100" style={{backgroundColor: "#282c4e"}}>
                    <div>
                    <p style={{color: "#f9004d", textDecoration: "uppercase", fontSuze:"calc(18px + (21 - 18) * ((100vw - 320px)/(1920 - 300)))"}}>Values</p>
                    <h2 className="core-values" >Core values are fundamental to the working spirit of Our Company</h2>
                    <CarouselOfImages />
                    </div>
                </div>
                {/* End of Core Values section/*}

                {/* Start Finding Us Area  */}
                {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper col-lg-6">
                            <div className="content ">
                                <h4 className="theme-gradient">Mission</h4>
                                <p>We aim at transforming the digital experience of our customer into cost effective, functional, user-centric and innovative technical solution, Our company recognizes and adapts quickly to the changing digital landscape thereby empowering clients to uplift their presence in the market.</p>
                                <a className="rn-btn btn-white" href="/about">Get Started</a>
                            </div>
                        </div>
                        <div className="thumbnail col-lg-6">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.jpg" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <p style={{textAlign: "center", color: "#f9004d"}}>Our Offices</p>
                        <h2 className="fontWeight500" style={{textAlign: "center"}}>Our company global engagement</h2>
                        <div className="offices" >
                            <div className="office_content">
                                <div className="office-inner-box">
                                    <p className="offices-header">Development Center</p>
                                    <p><img className="flag_image" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" width="40" height="25"/>India</p>
                                    <p>307,Sundarpark, <br/> Gandhidham, Gujrat-370201</p>
                                </div>
                                    <div className="d-flex justify-content-between contact_us">
                                    <NavLink to="/contact">
                                        <p className="button">
                                        Contact Us
                                        </p>
                                        </NavLink>
                                    </div>
                            </div>
                            <div className="office_content">
                                <div className="office-inner-box">
                                    <p className="offices-header">Development Center</p>
                                    <p><img className="flag_image" src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg" width="40" height="25"/>Canada</p>
                                    <p>39 Shepton Way <br/> Scarborough, Ontario-M1V 5N4</p>
                                </div>
                                    <div className="d-flex justify-content-between contact_us">
                                        <NavLink to="/contact">
                                            <p className="button">
                                            Contact Us
                                            </p>
                                        </NavLink>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About