import React from 'react';
import { FaPlaneDeparture, MdCardTravel } from 'react-icons/fa';
import healthImage1 from '../../../public/assets/images/healthcare/health-1.jpg'
import healthImage2 from '../../../public/assets/images/healthcare/health-2.jpg'
import healthImage3 from '../../../public/assets/images/healthcare/health-3.jpg'
import travelPlantImage from '../../../public/assets/images/healthcare/travel-plant.jpg'


export default function TravelandHospitalityImageTextContainer() {
  return (
    <div>
        <div className="wrap">
        <div className='row new-tech' style={{backgroundImage: `url("https://images.pexels.com/photos/4210780/pexels-photo-4210780.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500")`}}>
                
                <div className='col-lg-6'>
                    <div >
                        <div className="h2 dark:text-themelight-0">Seek New Technologies</div>
                        <p style={{fontSize:"1.125rem"}}>Helping travel agency, Tour Operator and DMC business to manage bookings, marketing, customer support and more.</p>
                    </div>
                    </div>
                
                        <div className='row d-lg-none' style={{width: "100%", margin:0}}>
                        <div className='col-sm-6 col-xs-12 ' style={{padding: "10px"}} >
                        <div className='inner1 ' style={{backgroundColor:"#2aacbbf0", padding:"10px"}}>
                            <h3>Providers</h3>
                                <p> Bend with the curve to provide better healthcare services to your customers at an affordable cost. With our Healthcare management solutions – enable seamless collaboration and deliver better patient care.</p>
                            </div>
                            </div>
                            <div className='col-sm-6 col-xs-12 d-lg-none ' style={{padding: "10px"}} >
                            <div className='inner1' style={{backgroundColor:"#2aacbbf0", padding:"10px"}}>
                            <h3>Providers</h3>
                                <p> Bend with the curve to provide better healthcare services to your customers at an affordable cost. With our Healthcare management solutions – enable seamless collaboration and deliver better patient care.</p>
                            </div>
                            
                        </div>
                        </div>
                            
                       
                        
        <div className='col-lg-6 d-none d-lg-block'>
          <div className='row'>
          <div className='w30 object-fit'>
                <img src={healthImage1} width="140" height="180" />
              </div>
              
            <div className='w30 textbox-1 squrecontent-second  text-center' style={{backgroundColor:"#2aacbb"}}>
              <div className='inner1 '>
              <FaPlaneDeparture size="70" />
              <h3>Providers</h3>
              <div>
                <p>Adage to new techno-trends to get smoother management and better revenue.</p>
              </div>
              </div>
              
            </div>
            
          </div>

          <div className='row'>
          <div className='w30 textbox-1 squrecontent-second  text-center' >
              <div className='inner1 '>
              <FaPlaneDeparture size="70" />
              <h3>Comfy Stays</h3>
              <div>
                <p>Great your guests with mobility solutions and deliver brand promise constantly.</p>
              </div>
              </div>
              
            </div>
          <div className='w30 object-fit'>
                <img src={healthImage1} width="140" height="180" />
              </div>

          </div>
          </div>
        </div>
        </div>
    </div>
  )
}
