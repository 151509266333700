import React from 'react';
import healthImage1 from '../../../public/assets/images/healthcare/health-1.jpg'
import healthImage2 from '../../../public/assets/images/healthcare/health-2.jpg'
import healthImage3 from '../../../public/assets/images/healthcare/health-3.jpg'


export default function ImageTextContainer() {
  return (
    <div>
        <div className="wrap">
          <div className='row'>
          <div className='w30 object-fit'>
                <img src={healthImage1} width="140" height="180" />
              </div>
              
            <div className='w30 textbox-1'>
              <div className='inner1'>
              <h3>Providers</h3>
                <p> Bend with the curve to provide better healthcare services to your customers at an affordable cost. With our Healthcare management solutions – enable seamless collaboration and deliver better patient care.</p>
              </div>
              
            </div>
            <div className='w30 object-fit'>
                <img src={healthImage2} width="140" height="180" />
              </div>
          </div>
          <div className='row'>
          <div className='w30 textbox-1'>
              <div className='inner1'>
              <h3>Prayers</h3>
                <p> Prayers are becoming the center-point of focus in healthcare landscape. We help you to simplify the complexity of emerging technology with healthcare software development solutions, adnd make informed decisions and save expenses.</p>
              </div>
              
            </div>
          <div className='w30 object-fit'>
                <img src={healthImage3} width="140" height="180" />
              </div>
              
            
              <div className='w30 textbox-1'>
              <div className='inner1'>
              <h3>Pharmaceuticals and Biotech</h3>
                <p> Re-invent your business model with emerging trends to optimize your operations, increase your effeciency flexibility, meet changes in IT trends, cosumer patterns and declining revenue base.</p>
              </div>
              
            </div>
          </div>
        </div>
    </div>
  )
}
