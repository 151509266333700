import React from 'react'

export default function WhyUnanimous() {
  return (
    <div>
        {/* <img className="image1" src="https://picsum.photos/400/500" height="350"/> */}
        <div className="row h-100 ptb--40">
                <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                        <div className="section-title text-center">
                            <h2 className="title theme-gradient">Why Unanimous's Healthcare Management Solitions?</h2>
                            <p style={{padding: "0px 30px 30px 0"}}>Comprehensive experience in catering to healthcare organizations. Build valuable partenership with our healthcare management software and acumen to render "Win-all" Solutions</p>
                        </div>
                </div>
                <div className="col-lg-6" style={{backgroundColor:"#CCFFFF", padding: "30px"}}>
                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>

                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>

                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>
                </div>
                {/* <div className="col-lg-2" style={{backgroundColor:"red", color: "#fff"}}>
                    <img src="https://media.istockphoto.com/photos/mobile-phone-top-view-with-white-screen-picture-id1161116588?k=20&m=1161116588&s=612x612&w=0&h=NKv_O5xQecCHZic53onobxjqGfW7I-D-tBrzXaPbj_Q=" />
                </div> */}
                <div className="col-lg-6 right-section" style={{backgroundColor:"#0B0B45", color: "#fff", padding: "30px"}}>
                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>

                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>

                    <h3>Improve Paitent Centricity</h3>
                    <p>Harness the driving force of paitent engagement in healthcare and grab new technologies to lead the race.</p>
                </div>
            </div>
        </div>
  )
}
