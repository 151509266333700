import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderFour";
import Footer from "../../component/footer/Footer";
import RetailTextImageContainer from "./RetailTextImageCotainer";
import SoftwareDevelopment from './SoftwareDevelopment.jsx';
import WhyUnanimous from "./WhyUnanimous";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import { FaPlaneDeparture, FaShippingFast, FaHotel } from 'react-icons/fa';
import bgImageTravel from '../../../public/assets/images/bg/bg-image-38.jpg';
import RetailSoftwareSolutions from "./RetailSoftwareSolutions";


const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Healthcare extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Healthcare' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area ptb--40 pb--190 bg_image bg_image--36"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Retail Management Software Solutions</h2>
                                    <p>Disrupting retail application for a personalized experience</p>
                                    <p>Woo your customers with personalized shopping experiences created as ‘what they want’ - products and information with extravagant retail software solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Healthcare management Area  */}
                <div className="rn-about-area ptb--40 bg_color--1" style={{paddingTop: "80px"}}>
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row align-items-center" style={{margin: "20px"}}>
                                <div className="col-lg-12 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">Retail Management Solutions</h2>
                                            <p>Encompass the digital trends in Retail industry to improvise your online/brick and mortar store experience, increase profitability and reduce operation costs with high-quality retail management software</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Healthcare management Area  */}

                {/* Start Image text Area  */}
                <div className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper">
                            <div className="row row--35">
                                <RetailSoftwareSolutions />
                                
                            </div>
                    </div>
                </div>
                {/* End Image text Area  */}

                {/* Start Contact Top Area  */}
                <div className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 ptb--40" >
                            <div className="container-fluid">
                                <RetailTextImageContainer />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                    <div className="rn-about-wrapper" style={{backgroundColor: "#F5F5F5"}}>
                        <div className="container-padding-right">
                            <div className="row" style={{paddingTop: "40px"}} >
                            <div className="container">
                            <h1>Healthcare Software Development</h1>
                                <SoftwareDevelopment />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>
                    {/* Start Image text Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 new-tech " style={{backgroundImage: `url(${bgImageTravel})`, backgroundAttachment: "fixed", backgroundSize:"cover", backgroundPosition:"left bottom"}}>

                            <div className="col-lg-6 col-m-6 col-sm-12 pt--120" >
                                        
                                </div>
                                <div className="col-lg-6 col-m-6 col-sm-12 pt--120 " style={{backgroundColor:"rgba(182,217,213,1)", color:"#ffffff"}} >
                                        <div style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                                        <h2 class="travelFont">Why choose our Travel Software Solutions?</h2>
                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>

                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>

                                    <div class="h3">Add Techho-Care</div>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>
                                        </div>
                                        
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Image text Area  */}            

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Healthcare