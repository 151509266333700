import React from 'react';
import healthImage1 from '../../../public/assets/images/healthcare/health-1.jpg'
import healthImage2 from '../../../public/assets/images/healthcare/health-2.jpg'
import healthImage3 from '../../../public/assets/images/healthcare/health-3.jpg'


export default function SoftwareDevelopment() {
  return (
    <div>
      <div class="software-container">
        <div class="column-1 box">
          <h3>IT Stratergy Consulting</h3>
          <p>Nothing succeeds like a sure-shot cure for diseases- likewise get exclusively designed solutions to meet your distinctive needs. Also, imbibe deep knowledgeable insights that ensure success with:</p>
          <ol>
              <li>Better decision making</li>
              <li>Analysis and reports</li>
              <li>Revenue management</li>
              <li>Performance management</li>
              <li>Customer Service platform</li>
          </ol>
        </div>
        <div class="column-2 box">
          <h3>Tailored Healthcare Application</h3>
          <p>Shift your focus to value-based models to deliver better experiences. Provide your consumers with the power to make informed decisions. With Unanimous as your partener, get bespoke applications with:</p>
          <ol>
              <li>Effecient operations</li>
              <li>Seamless collaboration</li>
              <li>Transparent communication</li>
              <li>Improved costs and superior quality</li>
              <li>Business insights</li>
          </ol>
        </div>
      </div>
      <div class="software-container">
        <div class="column-1 box">
          <h3>Data Integration and Migration</h3>
          <p>Revitalize your healthcare systems with effective integration of multiple systems at one platform to deliver better care to your paitents. Engage with Unanimous to integrate IT systems:</p>
          <ol>
              <li>Ideation to integration</li>
              <li>High customization and configuration</li>
              <li>Testing and quality assurance</li>
              <li>Support and maintenance</li>
              <li>Social engagement</li>
          </ol>
        </div>
        <div class="column-2 box">
          <h3>Paitent Engagement</h3>
          <p>In this digital healthcare age, paitents demand to be informed more than ever. Meet these rising expectations and engage with your paitents at all possible touchpoints to create a paitent-centric ecosystem:</p>
          <ol>
              <li>Operational effeciencies</li>
              <li>Streamlined workflows</li>
              <li>loT and wearables integration</li>
              <li>Re-engineering legacy systems</li>
              <li>Improvised customer satisfaction</li>
          </ol>
        </div>
      </div>
    </div>
  )
}
