import React from 'react';
import { FaPlaneDeparture, MdCardTravel } from 'react-icons/fa';
import healthImage1 from '../../../public/assets/images/healthcare/health-1.jpg'


export default function RetailTextImageContainer() {
  return (
    <div>
        <div className="wrap">
        <div className='row new-tech' style={{color:"#fff"}}>
                
                <div className='col-lg-6' style={{backgroundColor:"#050A30"}}>
                    <div className='left-line'>
                        <div className="h2">Competitive Retail Management Solutions</div>
                        <div class="relative">
                        <p style={{fontSize:"1.125rem"}}>You are in the middle of Retail Boom- It is the right time to build timeless relationship with your customers through aesthetically designed retail management solutions. Be it Cloud, IoT or Mobile, tear the competition and rule in with your customers.</p>
                        <p style={{fontSize:"1.125rem"}}>Re-tell your success story with effective use of real-time data, POS insights, and multi-platform execution. With social intelligence, anticipate insights and make your brand lively across channels.</p>
                        </div>
                    </div>
                    </div>
                
                        <div className='row d-lg-none' style={{width: "100%", margin:0}}>
                            <div className='col-sm-12 col-xs-12 d-lg-none ' style={{padding: "10px"}} >
                            <div className='inner1' style={{backgroundColor:"#2aacbbf0", padding:"10px"}}>
                            <h3>Providers</h3>
                                <p> Bend with the curve to provide better healthcare services to your customers at an affordable cost. With our Healthcare management solutions – enable seamless collaboration and deliver better patient care.</p>
                            </div>
                            
                        </div>
                        </div>
                            
                       
                        
        <div className='col-lg-6 d-none d-lg-block'>
          <div className='row'>
          <div className='w30 object-fit'>
                <img src={healthImage1} width="140" height="180" />
              </div>
              
            <div className='w30 textbox-1 squrecontent-second  text-center' style={{backgroundColor:"#2aacbb"}}>
              <div className='inner1 '>
              <FaPlaneDeparture size="70" />
              <h3>Providers</h3>
              <div>
                <p>Adage to new techno-trends to get smoother management and better revenue.</p>
              </div>
              </div>
              
            </div>
            
          </div>

          <div className='row'>
          <div className='w30 textbox-1 squrecontent-second  text-center' >
              <div className='inner1 '>
              <FaPlaneDeparture size="70" />
              <h3>Comfy Stays</h3>
              <div>
                <p>Great your guests with mobility solutions and deliver brand promise constantly.</p>
              </div>
              </div>
              
            </div>
          <div className='w30 object-fit'>
                <img src={healthImage1} width="140" height="180" />
              </div>

          </div>
          </div>
        </div>
        </div>
    </div>
  )
}
