import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../../elements/contact/ContactTwo";
import BrandTwo from "../../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/HeaderFour";
import Footer from "../../component/footer/Footer";
import styles from "./_healthcare.scss";
import ImageTextContainer from "./ImageTextContainer";
import SoftwareDevelopment from './SoftwareDevelopment.jsx';
import WhyUnanimous from "./WhyUnanimous";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Healthcare extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Healthcare' />

                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                
                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area ptb--40 pb--190 bg_image bg_image--34"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Healthcare Software Solutions</h2>
                                    <p>Optimized diadnosis, daily treatment, and better medical workflows</p>
                                    <p>There road to good healthcare is paved by adapting latest trends of patient engagement and effective healthcare management solutions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Healthcare management Area  */}
                <div className="rn-about-area ptb--40 bg_color--1" style={{paddingTop: "80px"}}>
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row align-items-center" style={{margin: "20px"}}>
                                <div className="col-lg-12 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">Healthcare Management Software</h2>
                                            <p>Healthcare organizations need to stay healthy with the technology dose to operate smartly, accelerate strategic goals and revamp the quality of Service</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Healthcare management Area  */}

                {/* Start Image text Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right" style={{backgroundColor: "#282c4e"}}>
                            <div className="row row--35 ">
                                <div className="col-lg-4 col-m-12" >
                                        <div className="text-right healthcare-title">
                                            <h2>Re-energize.</h2>
                                            <h2>Re-furbish.</h2>
                                            <h2>Re-think.</h2>
                                        </div>
                                </div>
                                <div className="col-lg-8 col-m-12 rn-page-title-area pt--120 pb--190 bg_image bg_image--35 healthcare-page-title" style={{backgroundAttachment: "fixed"}} data-black-overlay="6">
                                    <h2>Revive Health IT</h2>
                                    <h2>Add Techho-Care</h2>
                                    <p>Healthcare industry is undergoinig crucial changes with heavy pentration of emerging technology in both-providers as well as paitent segments. The time is jow to transform your current systems to cloud, mobility and lot driven experiences for cosumers (red-patients).</p>
                                    <p>Recuperate health systems with aptly designed by Unanimous that add more "care" to healthcare and not become just systems.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Image text Area  */}
                <div className="rn-about-area ptb--40 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row align-items-center" style={{margin: "20px"}}>
                                <div className="col-lg-12 col-m-12">
                                    <div className="about-inner inner" style={{paddingLeft: "10px"}}>
                                        <div className="section-title text-center">
                                            <h2 className="title theme-gradient">Healthcare Management Software</h2>
                                            <p>Enabling a healthy ecosystem with Healthcare software development - that empowers organizations to develop loyal users, make a difference with superior health outcomes and reduce operational costs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

{/* Start Contact Top Area  */}
<div className="rn-about-area bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 ptb--40" >
                            <div className="container-fluid">
                                <ImageTextContainer />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                    <div className="rn-about-wrapper" style={{backgroundColor: "#F5F5F5"}}>
                        <div className="container-padding-right">
                            <div className="row" style={{paddingTop: "40px"}} >
                            <div className="container">
                            <h1>Healthcare Software Development</h1>
                                <SoftwareDevelopment />
                            
                            </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>
                    <div className="rn-about-wrapper">
                        <div className="container-padding-right">
                            <div className="row row--35 " >
                            <div className="container-fluid image-over-columns">
                                <WhyUnanimous />
                            </div> 
                            </div>
                            
                        </div>
                    </div>                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Healthcare