import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import ItemsCarousel from "react-items-carousel";

import "./styles.css";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const isDesktop = useMediaQuery({ query: '(min-width: 1350px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 760px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  
  let data = [
    {
      title: "Integrity",
      des: "Accountability and being genuine in what we do inculcates reliability and we sustain on this virtue.",
      imgSrc: "https://www.openxcell.com/wp-content/uploads/2021/09/our-value11.svg"
    },
    {
      title: "Transparency",
      des: "We maintain complete transparency in our operations at all levels and ensure client is fully intimated about our processes.",
      imgSrc: "https://www.openxcell.com/wp-content/uploads/2021/09/value21.svg"
    },
    {
      title: "Quality Assurance",
      des: "We ensure premium quality in all our services and guaranteed delivery of technically sound products.",
      imgSrc: "https://www.openxcell.com/wp-content/uploads/2021/09/value31.svg"
    },
    {
      title: "Value for people",
      des: "We foster growth and nurture lives by providing a workplace conducive to constant learning.",
      imgSrc: "https://www.openxcell.com/wp-content/uploads/2021/09/value41.svg"
    },
    {
      title: "Commitment",
      des: "We pursue our clients by being consistent and committed to the delivery of results.",
      imgSrc: "https://www.openxcell.com/wp-content/uploads/2021/09/value51.svg"
    }
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleDelete = () => {
    alert("Call delete");
  };

  return (
    <div style={{ padding: `0 ${chevronWidth}px` }}>
    {/* {data.map(data => {
        return (
          <div key={data.title}>
            <h1>{data.title}</h1>
          </div>
        )
      })} */}
      <ItemsCarousel
        placeholderItem={<div style={{ height: 200, background: "#EEE" }} />}
        enablePlaceholder={true}
        numberOfPlaceholderItems={5}
        numberOfCards={isDesktop ?  4.5 : isTablet ? 2 : isMobile ? 1 : 4.5}
        gutter={12}
        slidesToScroll={1}
        chevronWidth={60}
        outsideChevron={true}
        showSlither={false}
        firstAndLastGutter={false}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        rightChevron={<img src="https://img.icons8.com/material/24/4a90e2/circled-chevron-right--v1.png"/>}
        leftChevron={<img src="https://img.icons8.com/material/24/4a90e2/circled-chevron-left--v1.png"/>}
      >
      
        {loading
          ? []
          : data.map((_, i) => (
              <div className="container_image" key={data[i].title} style={{textAlign: "center", background: "#fff"}}>
                <div  
                  key={i}
                  
                >                
                <div class="container">
                  <div className="row row--35 outer-box">
                  
                      <div className="col-lg-12">
                      
                            <img src = {data[i].imgSrc} width="49" height="49" alt={data[i].title} />
                            <h3>{data[i].title}</h3>
                            <p>{data[i].des}</p>
                       
                        
                      </div>
                      
                  </div>
                </div>
                
                </div>
              </div>
            ))}
      </ItemsCarousel>
    </div>
  );
}
