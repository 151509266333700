import React from 'react';
import ShoppingBag from '../../../public/assets/images/healthcare/shopping-bag.png'

export default function RetailSoftwareSolutions() {
  return (
      <div className='container'>
    <div class="grid-container">
        <div class="item1">
            <img src={ShoppingBag} height="300" />
        </div>
        <div class="item2">
            <p className='title'>Fashion and Apparel</p>
            <p>Deliver memorable customer experiences with over-the-top performance to become a trend setter</p>
        </div>
        <div class="item3">
            <p className='title'>Fashion and Apparel</p>
            <p>Deliver memorable customer experiences with over-the-top performance to become a trend setter</p>
        </div>  
        <div class="item4">
            <p className='title'>Fashion and Apparel</p>
            <p>Deliver memorable customer experiences with over-the-top performance to become a trend setter</p>
        </div>
        <div class="item5">
            <p className='title'>Fashion and Apparel</p>
            <p>Deliver memorable customer experiences with over-the-top performance to become a trend setter</p>
        </div>
</div>
</div>
  )
}
